/* UpdateCircular.css */

.UpdateCircular-container {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .UpdateCircular-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .UpdateCircular-loading,
  .UpdateCircular-error,
  .UpdateCircular-message {
    font-size: 16px;
    margin: 20px 0;
  }
  
  .UpdateCircular-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .UpdateCircular-table th,
  .UpdateCircular-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .UpdateCircular-table th {
    background-color: #f2f2f2;
  }
  
  .UpdateCircular-file-link {
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease;
  }
  
  .UpdateCircular-file-link:hover {
    color: #0056b3;
  }
  
  .UpdateCircular-edit-button {
    padding: 8px 12px;
    margin: 0 5px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .UpdateCircular-delete-button 
  {
    padding: 8px 12px;
    margin: 0 5px;
    margin-top: 5px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease; 
  }
  
  .UpdateCircular-edit-button:hover,
  .UpdateCircular-delete-button:hover {
    background-color: #0056b3;
  }
  
  .UpdateCircular-edit-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .UpdateCircular-input,
  .UpdateCircular-textarea,
  .UpdateCircular-file-input,
  .UpdateCircular-save-button {
    margin-bottom: 15px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .UpdateCircular-save-button {
    background-color: #28a745;
    color: #fff;
    cursor: pointer;
  }
  
  .UpdateCircular-save-button:hover {
    background-color: #218838;
  }
  
  /* Add additional styles or modify existing ones based on your preferences */
  