/* Add these styles to your Circular.css file or style tag in your React component */

.circular-container {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .circular-form {
    display: flex;
    flex-direction: column;
  }
  
  .circular-input-group {
    margin-bottom: 20px;
  }
  
  .circular-label {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .circular-input
  {
    width: 80%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .circular-update-btn
  {
    margin-top: 5px;
    text-align: center;
    background-color: #1354a9;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .circular-file
  {

    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .circular-textarea
  {
    width: 80%;
    padding: 40px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .circular-submit-btn {
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .circular-submit-btn:hover {
    background-color: #45a049;
  }
  
  .circular-upload-date {
    margin-top: 20px;
    text-align: center;
  }
  
  /* Optional: Add animations for more interactive effects */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .circular-container {
    animation: fadeIn 0.5s ease;
  }
  