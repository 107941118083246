/* StudentInfo.css */

/* Base styles */
.info-student-info-container {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    padding: 20px;
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .info-title3 h2
  {
    margin-left: 40%;
    
  }
  .info-delete
  {
    display:block;
    margin-top: 5px;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

  }
  .info-center {
    text-align: center;
  }
  
  .info-search-section {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-right: 50%;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Search input */
  .info-register {
    padding: 8px; /* Adjusted padding */
    width: 120px;
    font-family: Quicksand;
    font-size: 14px; /* Adjusted font size */
    outline: none;
    background-color: #f0f3f6;
    border-radius: 10px; /* Adjusted border radius */
    transition: border-color 0.3s ease-in-out;
  }
  
  .info-register:focus {
   
    outline: none;
  }
  
  .info-details {
    margin-left: 10px;
    padding: 8px; /* Adjusted padding */
    font-size: 16px; /* Adjusted font size */
    border: none;
    margin-bottom: 12px;
    border-radius: 8px; /* Adjusted border radius */
    background-color: #007bff;
    color: #fff;
    height: 45px;
    cursor: pointer;
    width: 200px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .info-details:hover {
    background-color: #0056b3;
  }
  
  /* Student details table */
  .info-details-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .info-field-label {
    padding: 10px;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    background-color: #f8f9fa;
  }
  
  .info-field-input {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .info-update-text {
    width: 100%;
    padding: 8px;
    font-family: Quicksand;
    border: 1px solid #ced4da;
    border-radius: 20px;
    font-size: 14px;
    transition: border-color 0.3s ease-in-out;
  }
  
  .info-update-text:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .info-student-photo-container {

    margin-bottom: 20px;
  }
  
  .info-student-photo {
    max-width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }

  /* Error message */
  .info-error-message {
    color: #dc3545;
    margin-top: 10px;
  }
  
  /* Change button and file input */
  .info-change,
  .info-file {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .info-change:hover,
  .info-file:hover {
    background-color: #28a745;
  }
  
  .info-change-profile-pic {
    margin-top: 20px;
  }
  
  /* Add animation to the form */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .info-student-info-container,
  .info-student-info-container > div {
    animation: fadeIn 0.5s ease-in-out;
  }
  