/* Login.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Set your preferred background color */
}

.login-form {
  width: 400px;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.login-form h1 {
  text-align: center;
  font-family: Quicksand;
  margin-bottom: 30px;
  color: #333;
}

input[type='text'],
input[type='password'] {
  font-weight: lighter;
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 20px;
}

input[type='text']:hover,
input[type='password']:hover,
button:hover {
  border-color: #555;
}

button {
  width: 100%;
  font-family: Quicksand;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #007bff; /* Set your preferred button color */
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Quicksand;
}

button:hover {
  background-color: #0056b3; /* Set your preferred hover button color */
}

.signup-link {
  text-align: center;
  margin-top: 20px;
}

.signup-link a {
  color: #007bff; /* Set your preferred link color */
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}


@media only screen and (max-width: 768px) {
  .login-form h1 {
    text-align: center;
    font-family: 'Quicksand';
    margin-bottom: 30px;
    color: #333;
  }

}