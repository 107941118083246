/* StudentData.css */

/* Student Data Container */
.student-data-container {
  max-width: 90%;
  margin-top: 25px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
}

/* Heading */
.data-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Filter Section */
.filter-section {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on small screens */

  align-items: center;
  margin-bottom: 20px;
}

/* Filter Label */
.filter-label {
  margin-right: 10px;
  font-weight: bold;
}

/* Filter Select */
.filter-select {
  width: 80px;
  padding: 8px;
  font-size: 16px;
}

/* Filter Input */
.filter-input {
  width: 100%; /* Take up full width */
  max-width: 300px; /* Limit maximum width for larger screens */
  margin-top: 10px; /* Adjust margin for spacing */
  outline: none;
  padding: 8px;
  font-size: 16px;
}

/* Details Button */
.details-button {
  font-size: 16px;
  padding: 10px;
  font-weight: bold;
  background-color: #007bff;
  color: #fff;
  border: none;
  margin-left: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.details-button:hover {
  background-color: #48A878;
}

/* Student Table */
.student-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

/* Table Header */
.table-header {
  font-weight: bold;
  background-color: rgb(21, 20, 20);
  color: #fff;
  padding: 10px;
}

/* Table Rows */
.student-table tbody tr {
  border-bottom: 1px solid #ddd;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

/* Next Button */
.next-button {
  width: 100px;
  align-items: center;
  font-size: 16px;
  padding: 14px;
  font-weight: bold;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.next-button:hover {
  background-color: #48A878;
}
