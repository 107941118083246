/* Attendance.css */

/* Styles for the attendance container */
.attendance-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Styles for the attendance heading */
.attendance-heading {
  text-align: center;
  font-size: 2.3rem;
  margin-bottom: 20px;
}

/* Styles for the filters section */
.attendance-filters {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

/* Styles for labels */
.attendance-label {
  font-weight: bold;
  margin-right: 10px;
}

/* Styles for input and select elements */
.attendance-input,
.attendance-select,
.attendance-input[type='date'],
#searchInput,
.attendance-save-button {
  font-family: Quicksand;
  font-size: 1rem;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.attendance-dept {
  margin-left: 25px;
  font-weight: bold;
  font-size: 1.2rem;
}

#departmentFilter {
  padding: 10px;
  margin-left: 10px;
}

.attendance-input[type='date'] {
  padding: 12px;
  margin-left: 10px;
  width: 100%;
  margin-bottom: 15px;
}

#batchFilter {
  margin-left: 15px;
}

/* Styles for the attendance table */
.attendance-table {
  width: 100%;
  border-collapse: collapse;
}

.attendance-table th,
.attendance-table td {
  border: 1px solid #ccc;
  padding: 15px;
  text-align: left;
}

/* Styles for the save button */
.attendance-save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
  margin-bottom: 20px;
}

.attendance-pagination {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for the pagination buttons */
.attendance-pagination-button {
  padding: 10px 15px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.attendance-pagination-button:hover {
  background-color: #0056b3;
}

/* Styles for the save button */
.attendance-save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
  margin-bottom: 20px;
}

.attendance-save-button:hover {
  background-color: #0056b3;
}
.attendance-save-button:hover {
  background-color: #0056b3;
}

.attendance-select {
  font-family: Quicksand;
  font-size: 1rem;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

/* Styles for the options within the select element */
.attendance-select option {
  font-family: Quicksand;
  font-size: 1rem;
}



/* Media queries for responsiveness */
@media screen and (min-width: 768px) {
  .attendance-filters {
    justify-content: flex-start;
  }
  .attendance-input,
  .attendance-select,
  .attendance-input[type='date'],
  #searchInput,
  .attendance-save-button {
    width: auto;
    margin-bottom: 0;
    margin-right: 10px;
  }
}
