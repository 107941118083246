/* Global Styles */
body {
    font-family: Quicksand;
    margin: 0;
    padding: 0;
    background-color: #f7f7f7;
    color: #333;
  }
  
  a {
    font-family: Quicksand;
    text-decoration: none;
    color: #0077cc;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #005299;
  }
  
  /* Header Styles */
  .homepage-header {
    font-family: Quicksand;
    text-align: center;
    background-color:  #0077cc ;
    padding: 40px;
    color: white;
  }
  
  .homepage-header h1 {
    font-size: 2.5rem;
    font-family: Quicksand;
    margin-bottom: 10px;
  }
  
  .homepage-header h2 {
    font-family: Quicksand;
    font-size: 1.8rem;
    margin-top: 0;
  }
  
  /* Featured Section Styles */
  .homepage-featured-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: Quicksand;
    padding: 40px;
    background-color: #fff;
  }
  
  .homepage-featured-content {
    flex: 1;
    text-align: left;
    margin-right: 20px;
    font-family: Quicksand;
  }
  
  .homepage-featured-content h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center;
    font-family: Quicksand;
  }
  
  .homepage-featured-content p {
    font-size: 1.2rem;
    font-family: Quicksand;
    text-align: center;
    margin: 0;
  }
  
  .homepage-featured-image .homepage-image {
    max-width: 100%;
    height: auto;
    font-family: Quicksand;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Key Features Section Styles */
  .homepage-key-features {
    text-align: center;
    font-family: Quicksand;
    padding: 40px;
    background-color: #f9f9f9;
  }
  
  .homepage-key-features h2 {
    font-size: 1.8rem;
    font-family: Quicksand;
    margin-bottom: 20px;
  }
  
  .homepage-feature-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    font-family: Quicksand;
  }
  
  .homepage-feature {
    flex: 1;
    padding: 20px;
    margin: 10px;
    background-color: #fff;
    border-radius: 5px;
    font-family: Quicksand;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .homepage-feature h3 {
    font-size: 1.2rem;
    font-family: Quicksand;
    margin: 0;
  }
  
  .homepage-feature:hover {
    transform: translateY(-5px);
    font-family: Quicksand;
  }
  /* ... (other styles to complete the remaining lines) */
  