/* tableattendance.css */

/* Container Styles */
.checking-attendance-view-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading Styles */
  .checking-attendance-view-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Filters Styles */
  .checking-attendance-view-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .checking-attendance-label {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .checking-attendance-input,
  .checking-attendance-select {
    padding: 12px;
    font-size: 16px;
    margin-bottom: 8px;
    border-radius: 8px;
    outline: none;
    border: none;
    margin-bottom: 8px;
    font-family: Quicksand;
  }
  
  /* Table Styles */
  .checking-attendance-view-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .checking-attendance-view-table th,
  .checking-attendance-view-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .checking-attendance-view-table th {
    background-color: #f2f2f2;
  }
  
  /* Hover Effect */
  .checking-attendance-view-table tbody tr:hover {
    background-color: #f5f5f5;
    transition: background-color 0.3s ease-in-out;
  }
  
  /* No. Column Style */
  .checking-attendance-view-table th:first-child,
  .checking-attendance-view-table td:first-child {
    width: 5%;
  }
  
  /* Professional Look */
  .checking-attendance-view-container,
  .checking-attendance-view-filters,
  .checking-attendance-view-table {
    border-radius: 8px;
    overflow: hidden;
  }
  /* table.css */

/* ... existing styles ... */

/* Pagination Styles */
.checking-attendance-pagination {
  list-style: none;
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.checking-attendance-pagination-button {
  padding: 14px;
  font-size: 14px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.checking-attendance-pagination-button:hover {
  background-color: #45a049;
}

  /* Responsive Design */
  @media screen and (max-width: 600px) {
    .checking-attendance-view-filters {
      flex-direction: column;
    }
  
    .checking-attendance-input,
    .checking-attendance-select {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  