/* StudentCircular.css */

.student-circular {
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
  }
  .file-image
  {
    width: 60%;
    height: 700px;
  }
  .circular-item {
    border: 1px solid #ddd;
    margin: 0 auto;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .circular-item:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .circular-item h3 {
    color: #333;
    margin-bottom: 10px;
    font-size: 20px;
    text-align: center;
  }
  
  .description {
    color: #555;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .date {
    color: #777;
    margin-top: 5px;
    font-size: 20px;
    text-align: center;
  }
  
  .file-iframe {
    margin-top: 15px;
    margin: 0 auto;
    width: 100%; 
  }
  
  .file-iframe img {
    max-width: 100%; /* Ensure images do not exceed the container width */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove extra spacing below the image */
  }
  
  .separator {
    border: 0.5px solid #ddd;
    margin-top: 20px;
  }
  
  /* Add more styles as needed */
  