body
{
  font-family: Quicksand;
}
#container
{
  margin-top: 25px;
}
.form-container {
  width: 80%;
  margin: 0 auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
}

/* Form title */
.form-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

/* Form */
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.form label {
  grid-column: span 1;
  margin-bottom: 10px;
  font-weight: bold;
}

.form input[type='text'] {
  width: calc(100% - 10px);
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 20px;
  font-size: 20px;
  font-family: Quicksand;
  transition: border-color 0.3s ease-in-out;
}

.form input[type='text']:focus {
  border-color: black;
  outline: none;

}
.form select {
    font-family: Quicksand;
  font-size: 1rem;
  padding: 8px;
  border-radius: 5px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

/* Submit button */
.submit-button {
  font-family: Quicksand;
  font-weight: bold;
  grid-column: span 2;
  width: 50%;
  padding: 12px;
  border: none;
  border-radius: 12px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.submit-button:hover {
  background-color: #48A878;
}

/* Add hover effects to form elements */
.form input[type='text']:hover {
  border-color: #66afe9;
  font-size: 20px;
}

.form input[type='text']::placeholder {
  color: #999;
  font-size: 20px;
}

.form input[type='text']:focus::placeholder {
  color: transparent;

}

/* Add subtle box-shadow effect on focus */
.form input[type='text']:focus {
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Add animation to the form */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-container,
.form-container form {
  animation: fadeIn 0.5s ease-in-out;
}

/* StudentForm.css */

/* Style for the photo field */
.label-photo {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

.input-photo {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.input-photo:hover {
  border-color: #666;
}

/* StudentForm.css */

/* Enhanced style for the "Add Custom Field" button */
.button-custom-field {
  font-family: Quicksand;
  padding: 12px;
  margin-right: 5px;
  width: 200px;
  font-size: 16px;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
  border: 2px solid #4CAF50;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

.button-custom-field:hover {
  background-color: #45a049;
  border-color: #45a049;
}

.button-custom-field:active {
  background-color: #3e8e41;
  border-color: #3e8e41;
  transform: translateY(1px);
}

.button-container {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between the buttons */
  margin-top: 20px; /* Add margin for spacing */
}



/* StudentForm.css */

/* Base styles */

/* Styling for larger screens */
/* Desktops and larger */
@media (min-width: 992px) {
  .form-container {
    width: 50%; /* Adjust width for larger screens */
    margin: 0 auto; /* Center the form on larger screens */
  }
}

/* Tablets */
@media (max-width: 991px) {
  .form-container {
    width: 70%; /* Adjust width for tablets */
    margin: 0 auto; /* Center the form on tablets */
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .form-container {
    width: 90%; /* Adjust width for mobile devices */
    margin: 0 auto; /* Center the form on mobile devices */
  }

  /* Style adjustments for smaller screens */
  .label-photo {
    font-size: 14px; /* Reduce font size for photo label on mobile */
  }

  .input-photo {
    font-size: 14px; /* Reduce font size for photo input on mobile */
  }

  .button-container {
    flex-direction: column; /* Stack buttons vertically on mobile */
    margin-top: 15px; /* Adjust margin between buttons on mobile */
  }

  .submit-button,
  .button-custom-field {
    width: 100%; /* Make buttons full width on mobile */
    margin-bottom: 10px; /* Add space between buttons on mobile */
  }
}

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.container {
  max-width: 1200px; /* Set a maximum width for the content */
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
}

header, section, footer {
  padding: 20px;
  background-color: #f0f0f0;
  margin-bottom: 20px;
}

/* Responsive styles for tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .container {
    max-width: 90%; /* Adjust maximum width for tablets */
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 767px) {
  header, section, footer {
    padding: 10px;
  }
}
