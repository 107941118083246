/* UserDashboard.css */

.dash-user-dashboard {
    max-width: 80%;
    margin: 0px auto;
    margin-top: 100px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
}
.dash-admin-button {
    background-color: #3498db;
    color: #fff;
    width: 100px;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 5px;
    transition: background-color 0.3s ease;
}

.dash-admin-button:hover {
    background-color: #2980b9;
}


.dash-title {
    font-size: 24px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    color: #333;
}

.dash-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.dash-table th, .dash-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.dash-table th {
    background-color: #f2f2f2;
}

.dash-row {
    transition: background-color 0.3s ease;
}

.dash-row:hover {
    background-color: #f5f5f5;
}

.dash-pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dash-pagination-button {
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    width: 100px;
    border-radius: 4px;
    margin: 0 5px;
    transition: background-color 0.3s ease;
}

.dash-delete-button {
    background-color: red;
    display: block;
    width: 80px; /* Full width on small screens */
    margin-top: 10px; /* Add space between buttons on small screens */
}

.dash-pagination-button {
    background-color: #45a049;
}

.dash-delete-button:hover,
.dash-pagination-button:hover {
    background-color: #45a049;
}

.dash-page-number {
    margin: 0 10px;
    font-size: 16px;
    color: #333;
}

.dash-delete-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

/* Media Queries for Responsiveness */

@media only screen and (min-width: 600px) {
    .dash-delete-button,
    .dash-pagination-button {
        width: auto; /* Reset width on larger screens */
    }
}
