/* studentlogin.css */

.studentlogin-container {
    max-width: 500px;
    margin: 0px auto;
    margin-top: 180px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 480px;
  }
  
  .studentlogin-heading {
    text-align: center;
    color: #333;
  }
  
  .studentlogin-input-container {
    margin-bottom: 15px;
  }
  
  .studentlogin-label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .studentlogin-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ddd;
    outline: none;
    border-radius: 4px;
    transition: border 0.3s;
  }
  
  .studentlogin-input:hover,
  .studentlogin-input:focus {
    border-color: #3498db;
  }
  
  .studentlogin-captcha-container {
    display: flex;
    align-items: center;
  }
  
  .studentlogin-captcha {
    font-size: 20px;
    margin-right: 10px;
    color: #555;
  }
  
  .studentlogin-regenerate-button {
    background-color: #3498db;
    color: #fff;
    padding: 10px;
    width: 28%;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .studentlogin-regenerate-button:hover {
    background-color: #207bbf;
  }
  
  .studentlogin-login-button {
    background-color: #2ecc71;
    color: #fff;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .studentlogin-login-button:hover {
    background-color: #27ae60;
  }
  
  .studentlogin-error-message {
    color: #e74c3c;
    margin-top: 10px;
    text-align: center;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .studentlogin-container {
      max-width: 100%;
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(-10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  