/* SignUp.css */

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Set your preferred background color */
}
.role
{
  font-family: Quicksand;
  font-size: 1rem;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  outline: none;
}
.signup-form {
  width: 400px;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.signup-form h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

input[type='text']
 {
  font-weight: lighter;
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 15px;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
input[type='password']
{
  font-weight: lighter;
  width: calc(100% - 20px);
  padding: 10px;
  margin-left: 4px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 18px;
  font-family: Quicksand;
}

input[type='text']:hover,
input[type='password']:hover,
button:hover {
  border-color: #555;
}

button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #007bff; /* Set your preferred button color */
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3; /* Set your preferred hover button color */
}

.login-link {
  text-align: center;
  margin-top: 20px;
}

.login-link a {
  color: #007bff; /* Set your preferred link color */
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
