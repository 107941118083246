/* MarkUpdate.css */

/* Base styles */
.update-mark-update-container {
  max-width: 80%;
  margin: 0 auto;
  margin-top: 80px;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
}

.update-form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5rem;
}

.input-width{
  width: 70%;
  border-radius: 12px;

}

.update-input-register-number {
  padding: 10px;
  outline: none;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 12px;
  font-family: Quicksand;
  transition: border-color 0.3s ease-in-out;
}

.update-input-register-number:focus {
  border-color: #007bff;
  outline: none;
}

.update-fetch-marks-button {
  padding: 10px;
  font-size: 16px;
  width: 20%;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.update-fetch-marks-button:hover {
  background-color: #0056b3;
}

/* Data table styles */
.update-data-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

thead th {
  padding: 15px;
  font-weight: bold;
  text-align: left;
  border-bottom: 2px solid #007bff;
}

tbody td {
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

.update-edit-input-exam,
.update-edit-input-subject,
.update-edit-input-grade,
.update-edit-input-marks {
  width: 50px;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-family: Quicksand;
  font-size: 8px;
  transition: border-color 0.3s ease-in-out;
}

.update-edit-input-exam:focus,
.update-edit-input-subject:focus,
.update-edit-input-grade:focus,
.update-edit-input-marks:focus {
  border-color: #007bff;
  outline: none;
}

.update-edit-button
{
  width: 60px;
  padding: 10px;
  font-size: 15px;
  border: none;
  margin-right: 5px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.update-delete-button {
  width: 60px;
  padding: 10px;
  font-size: 15px;
  margin-right: 5px;
  margin-top: 2px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.update-save-button {
  width: 50px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}





.update-save-button:hover,
.update-edit-button:hover,
.update-delete-button:hover {
  background-color: #28a745;
}

/* Add animation to the form */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 768px) {
  .update-mark-update-container {
    max-width: 100%;
  }

  .update-input-register-number {
    width: 100%;
    margin-bottom: 10px;
  }

  .update-fetch-marks-button {
    width: 100%;
    margin-left: 0;
  }
}
