/* Global Styles */
body {
    font-family: Quicksand, sans-serif;
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
  }
  
  /* Homework Container */
  .homework-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Header */
  .homework-container h2 {
    text-align: center;
    color: #343a40;
    margin-bottom: 20px;
  }
  
  /* Form Styles */
  .homework-form {
    display: flex;
    flex-direction: column;
  }
  
  /* Form Group */
  .homework-form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .homework-form-group label {
    margin-bottom: 5px;
    color: #495057;
    font-weight: bold;
  }
  
  .homework-form-group input,
  .homework-form-group textarea {
    padding: 10px;
    font-family: Quicksand;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s;
  }
  
  .homework-form-group input:focus,
  .homework-form-group textarea:focus {
    border-color: #80bdff;
    outline: none;
  }
  
  /* Button Styles */
  .homework-add-button,
  .homework-submit-button,
  .homework-remove-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .homework-add-button {
    background-color: #28a745;
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .homework-submit-button {
    background-color: #007bff;
    color: #ffffff;
  }
  
  .homework-remove-button {
    background-color: #dc3545;
    color: #ffffff;
    margin-top: 5px;
  }
  
  .homework-add-button:hover,
  .homework-submit-button:hover,
  .homework-remove-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .homework-add-button:hover {
    background-color: #218838;
  }
  
  .homework-remove-button:hover {
    background-color: #c82333;
  }
  
  /* Error and Loading Messages */
  .homework-error,
  .homework-loading {
    color: #dc3545;
    text-align: center;
    margin-top: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .homework-container {
      padding: 15px;
    }
  }
  
  @media (max-width: 576px) {
    .homework-form-group {
      flex-direction: column;
    }
  }
  