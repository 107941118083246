/* UserView.css */

/* Inside your UserView.css file or style block */
.userview-pagination-container {
  display: flex;
  justify-content: center; /* Optional: Center aligns the buttons */
  align-items: center;
}

.pagination-button {
  margin: 0 5px; /* Adjust the margin value as needed */
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: pointer;
}
.navigate-button
{
  width: 150px;
  margin-top: 25px;
}

.userview-pagination-number {
  margin: 0 5px; /* Adjust the margin value as needed */
  cursor: pointer;
  margin-top: 25px;
}

/* Additional styling for the active/current page number */
.userview-pagination-number.active {
  font-weight: bold;
  /* Add any desired styling for the active page number */
}


.userview-container {
    max-width: 80%;
    margin: 0px auto;
    padding: 50px;
    border-radius: 12px;
    margin-top: 150px;
    box-shadow: 4px 4px 10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    text-align: center; /* Center align the content */
  }
  
  .userview-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    font-size: 2.5rem;
  }
  
  .userview-search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .userview-search-input {
    font-family: Quicksand;
    padding: 10px;
    width: 40%;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 5px;
    margin-top: 15px;
    outline: none;
  }
  
  .userview-search-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    width: 150px;
    padding: 15px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    bottom: 30px;
    transition: background-color 0.3s ease;
  }
  
  .userview-search-button:hover {
    background-color: #0056b3;
  }
  
  .userview-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .userview-table th,
  .userview-table td {
    border: 1px solid #ddd;
    font-family: Quicksand;
    padding: 12px;
    text-align: left;
  }
  
  .userview-table th {
    background-color: #f2f2f2;
    font-family: Quicksand;
  }
  
  .userview-row {
    transition: background-color 0.3s ease;
    font-family: Quicksand;
  }
  
  .userview-row:hover {
    background-color: #f5f5f5;
  }
  
  .userview-button-container {
    display: flex;
    justify-content: center; /* Center align the buttons */
  }
  
  .userview-edit-button,
  .userview-delete-button {
    background-color: #45a049;
    color: #fff;
    width: 80px;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 5px;
    transition: background-color 0.3s ease;
  }
  
  .userview-update-button,
  .userview-cancel-button {
    background-color: #007bff;
    color: #fff;
    width: 80px;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 5px;
    transition: background-color 0.3s ease;
  }
  
  .userview-edit-button:hover,
  .userview-delete-button:hover,
  .userview-update-button:hover,
  .userview-cancel-button:hover {
    background-color: #31708f;
  }
  