/* NavigationBar.css */

/* Basic styles */
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 10px;
  }
  .cursor {
    cursor: pointer;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .erp-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .header {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .navbar {
    list-style-type: none;
    padding: 0;
    display: flex;
  }
  
  .navbar-item {
    margin-right: 20px;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropbtn {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    margin-right: 50px;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #444;
    min-width: 200px;
    z-index: 1;
  }
  
  .dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  

  .menu-icon {
    cursor: pointer;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 20px;
    width: 30px;
  }
  
  .bar1,
  .bar2,
  .bar3 {
    width: 100%;
    height: 3px;
    background-color: white;
    transition: transform 0.3s ease;
  }
  
  .open .bar1 {
    transform: rotate(-45deg) translate(-4px, 4px);
  }
  
  .open .bar2 {
    opacity: 0;
  }
  
  .open .bar3 {
    transform: rotate(45deg) translate(-5px, -5px);
  }
  
  /* Hover effects */
  .dropdown-content a:hover {
    background-color: #555;
  }
  
  /* Keyframes for animations */
  @keyframes slideIn {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Example animation usage */
  /* You can add this animation to any element you want to animate */
  .example-animation {
    animation: slideIn 0.5s ease forwards;
  }
  
  @media screen and (max-width: 768px) {
    .navbar {
      display: none;
    }
  
    /* Show menu icon and style it */
    .menu-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 30px;
      width: 30px;
      position: relative;
      z-index: 100;
      cursor: pointer;
      margin-right: 10px; /* Adjust spacing */
    }
  
    .bar1,
    .bar2,
    .bar3 {
      width: 100%;
      height: 4px;
      background-color: white;
      transition: transform 0.3s ease, opacity 0.3s ease; /* Add opacity transition */
    }
  
    .open .bar1 {
      transform: rotate(-45deg) translate(-5px, 5px);
    }
  
    .open .bar2 {
      opacity: 0;
    }
  
    .open .bar3 {
      transform: rotate(45deg) translate(-5px, -5px);
    }
  
    /* Style the mobile dropdown */
    .dropdown-content-mobile {
      display: block;
      position: absolute;
      background-color: #444;
      min-width: 200px;
      z-index: 1;
      top: 60px; /* Adjust distance from top of the page */
      left: 0; /* Place the dropdown at the left edge */
      right: 0; /* Stretch dropdown to the right edge */
      margin: 0 auto; /* Center the dropdown horizontally */
      animation: slideDown 0.5s ease forwards; /* Apply slide animation */
      list-style: none; /* Remove bullets from the main dropdown */
    }
  
    /* Define slide animation */
    @keyframes slideDown {
      from {
        transform: translateY(-10px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  
    /* Adjust the appearance of dropdown links */
    .dropdown-submenu {
      display: block;
      background-color: #444;
      padding-left: 20px;
      list-style: none; /* Remove bullets from the nested dropdown */
    }
  
    .dropbtn-mobile {
      background-color: transparent;
      color: white;
      border: none;
      cursor: pointer;
      padding: 8px;
      font-size: 20px;
      transition: transform 0.3s ease, opacity 0.3s ease;
    }
    
    .dropbtn-mobile:hover {
        background-color: transparent;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }
  
    .dropdown-content-mobile .dropdown-submenu a {
        display: block;
        padding: 12px 8px; /* Adjust padding for more space */
        color: white;
        text-decoration: none;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }
    
    .dropdown-content-mobile .dropdown-submenu a:hover {
      background-color: #555;
    }
  
    /* Show dropdowns on click */
    .show-mobile-dropdown {
      display: block !important;
    }
  }
  
  
  
  