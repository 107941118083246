/* StudentMark.css */

/* Form title */
.mark-unique-form-title {
  font-size: 2.8em;
  color: #333; /* Adjust text color */
  margin-bottom: 20px;
}

/* Main container */
.mark-unique-container-one {
  width: 80%;
  padding: 20px;
  margin: 0 auto;
  margin-top: 40px; /* Adjust margin-top */
  display: flex;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
}

/* Input fields */
.mark-unique-mark-input {
  margin: 10px;
  padding: 12px;
  width: 100%;
  font-size: 16px;
  font-family: Quicksand;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: border-color 0.3s ease;
}

/* Hover effect for inputs */
.mark-unique-mark-input:hover {
  border-color: #555;
}

/* Buttons */
.mark-unique-add,
.mark-unique-save,
.mark-unique-show,
.mark-unique-update,
.mark-unique-logout {
  margin: 10px;
  padding: 12px;
  width: 150px; /* Adjust button width */
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}



/* Hover effect for buttons */
.mark-unique-add:hover,
.mark-unique-save:hover,
.mark-unique-show:hover,
.mark-unique-update:hover,
.mark-unique-logout:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

/* Separate exam containers */
.mark-unique-exam-container {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

/* Table within each exam container */
.mark-unique-exam-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.mark-unique-exam-table th,
.mark-unique-exam-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.mark-unique-exam-table th {
  background-color: #f2f2f2;
}

/* Alternate row colors for table */
.mark-unique-exam-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Hover effect for table rows */
.mark-unique-exam-table tr:hover {
  background-color: #ddd;
}

/* Add animation to the form */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
