/* ResultView.css */

/* Container */
.ResultView-container {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 80px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading */
  .ResultView-container h2 {
    text-align: center;
    font-size: 35px;
    color: #333;
  }
  
  /* User Details */
  .ResultView-user-details {
    margin-bottom: 20px;
  }
  .ResultView-user-details p{
    font-size: 20px;
  }
  
  /* Logout Button */
  .ResultView-logout-button {
    background-color: #e74c3c;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 12px;
    margin-left: 45%;
    width: 10%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

  }
  
  .ResultView-logout-button:hover {
    background-color: #c0392b;
  }
  
  /* Loading Message */
  .ResultView-loading {
    color: #3498db;
    text-align: center;
    margin: 20px 0;
  }
  
  /* Results Section */
  .ResultView-results {
    margin-top: 20px;
  }
  
  /* Exam Container */
  .ResultView-exam {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 70px;
  }
  .ResultView-exam h3 {
    text-align: center;
    font-size: 18px;
  }
  
  /* Results Table */
  .ResultView-results-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
  }
  
  .ResultView-results-table th,
  .ResultView-results-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .ResultView-results-table th {
    background-color: #3498db;
    color: #fff;
  }
  
  /* No Results Message */
  .ResultView-no-results {
    color: #333;
    text-align: center;
    margin-top: 20px;
  }
  
  /* Error Message */
  .ResultView-error {
    text-align: center;
    margin-top: 20px;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .ResultView-container {
      max-width: 100%;
    }
    .ResultView-logout-button {
        background-color: #e74c3c;
        color: #fff;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 12px;
        width: 22%;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
                                                                                                    
      }
      
      .ResultView-logout-button:hover {
        background-color: #c0392b;
      }
  }
  @media screen and (max-width: 800px) {
    .ResultView-container {
      max-width: 100%;
    }
    .ResultView-logout-button {
        background-color: #e74c3c;
        color: #fff;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 12px;
        width: 22%;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
                                                                                                    
      }
      
      .ResultView-logout-button:hover {
        background-color: #c0392b;
      }
  }
  @media screen and (max-width: 1200px) {
    .ResultView-container {
      max-width: 100%;
    }
    .ResultView-logout-button {
        background-color: #e74c3c;
        color: #fff;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 12px;
        width: 22%;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
                                                                                                    
      }
      
      .ResultView-logout-button:hover {
        background-color: #c0392b;
      }
  }
  
  
  