/* reportview.css */

/* Container styles */
.report-container {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 40px;
  font-family: 'Quicksand';
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.report-heading {
  font-size: 32px;
  margin-bottom: 30px;
  text-align: center;
  color: #333;
}

/* Form styles */
.report-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
}

.report-form label {
  font-weight: bold;
  font-size: 18px;
}

.report-input,
.report-button {
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}

.report-button-container {
  display: flex;
  gap: 10px;
}

.report-button {
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s ease;
}

.report-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Statistics section styles */
.report-statistics-section {
  text-align: center;
  margin-bottom: 30px;
}

.report-statistics-section p {
  font-size: 20px;
  margin: 10px 0;
  color: #444;
}

/* Table styles */
.report-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.report-table th,
.report-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.report-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Button styles */
.report-action-button {
  padding: 10px;
  font-size: 15px;
  cursor: pointer;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.report-save-button {
  padding: 13px;
  font-size: 15px;
  cursor: pointer;
  width: 160px;
  background-color: 007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.report-save-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
.report-select {
  font-family: Quicksand;
  font-size: 16px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 90%;
  box-sizing: border-box;
}



.report-action-button:hover {
  background-color: #218838;
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Media queries for responsiveness */
@media screen and (min-width: 768px) {
  .report-form {
    justify-content: flex-start;
  }

  .report-input,
  .report-button,
  .report-button-container {
    width: auto;
    margin-bottom: 0;
  }
}
