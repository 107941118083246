  
  .Unauthorized-container {
    text-align: center;
    margin: 0 auto;
    align-items: center;
    margin-top: 80px;
    height: 50vh;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .Unauthorized-heading {
    color: #333;
    margin-top: 100px;
  }
  
  .Unauthorized-message {
    color: #666;
  }
  
  /* Button Styles */
  .Unauthorized-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 16px;
    width: 20%;
    color: #fff;
    background-color: #4285f4;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .Unauthorized-button:hover {
    background-color: #2a4b8d;
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Apply Animation to Container */
  .Unauthorized-container {
    animation: fadeIn 0.5s ease-in-out;
  }
  